import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { UserAuth } from 'src/app/authentication/profile/models/user-profile';
import { AuthenticationService } from 'src/app/authentication/services/authentication.service';
import { UserService } from 'src/app/authentication/services/user.service';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarNavService } from '../../services/sidebar-nav.service';

@Component({
    selector: 'app-sidebar-nav',
    templateUrl: './sidebar-nav.component.html',
    styleUrls: ['./sidebar-nav.component.scss'],
})
export class SidebarNavComponent implements OnInit, OnDestroy {
    isExpanded: boolean = true;
    isUserSectionExpanded: boolean = false;

    activeNavItem: number = 0;
    isAdmin: boolean;
    picture: string;
    username: string;
    userRoles: string[];

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private sidebarNavService: SidebarNavService
    ) {}

    ngOnInit(): void {
        this.authenticationService.initializationFinished$
            .pipe(
                take(1),
                switchMap(() =>
                    this.authenticationService
                        .observableUserAuth()
                        .pipe(takeUntil(this.destroy$))
                ),
                tap((userAuth: UserAuth) => {
                    this.username = userAuth.username;
                    this.isAdmin = this.authenticationService.isAdmin();
                    this.userRoles = userAuth.roles;
                }),
                switchMap(() => {
                    return this.userService.profilePicture$.pipe(
                        takeUntil(this.destroy$)
                    );
                }),
                tap((picture: string) => {
                    this.picture = picture;
                })
            )
            .subscribe();

        this.router.events.pipe(takeUntil(this.destroy$)).subscribe({
            next: (event) => {
                if (event instanceof NavigationEnd) {
                    this.setNavItemSelectionBasedOnUrl(event.url);
                }
            },
        });
        this.setNavItemSelectionBasedOnUrl(
            this.getPath().replace('/tt/fe', '')
        );
    }

    private getPath(): string {
        return location.pathname;
    }

    private setNavItemSelectionBasedOnUrl(url: string) {
        switch (url) {
            case '/workspace':
                this.activeNavItem = 0;
                break;
            case '/communityprojects':
                this.activeNavItem = 4;
                break;
            case '/profile':
                this.activeNavItem = 5;
                break;
            case '/admin':
                this.activeNavItem = 6;
                break;
        }
    }

    onExpand() {
        this.isExpanded = !this.isExpanded;
        if (!this.isExpanded) {
            this.isUserSectionExpanded = false;
        }
        this.sidebarNavService.sidebarNavExpansion$.next();
    }

    ngOnDestroy(): void {
        this.destroy$.complete();
    }

    onUserSectionExpand() {
        if (this.isExpanded) {
            this.isUserSectionExpanded = !this.isUserSectionExpanded;
        }
    }

    onLogOut() {
        this.authenticationService.signOut();
    }
}
