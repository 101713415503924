<div class="container-fluid h-100">
    <div class="skill-architecture-editor row flex-nowrap h-100 pb-5">
        <div class="skill-architecture-panel d-flex flex-column">
            <app-skill-architecture-selection-row
                [editingIsDisabled]="editingIsDisabled"
            >
            </app-skill-architecture-selection-row>
            <app-architecture-visualization
                (rendered)="onDataRendered($event)"
                (transform)="onTransform($event)"
                [initialTransform]="getTransform()"
                [editingIsDisabled]="editingIsDisabled"
                [drawAreaId]="'skill-architecture'"
                class="d-inline-block flex-fill"
            ></app-architecture-visualization>
        </div>
        <div class="right-column">
            <app-layer-context-menu
                [editingIsDisabled]="editingIsDisabled"
                class=""
                *ngIf="skillArchitectureStateService.selectedLayer$ | async"
            ></app-layer-context-menu>
        </div>
    </div>
</div>
