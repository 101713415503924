<div id="main-nav" class="navbar" (click)="onCloseOpenedMenuInNav($event)">
    <div class="middle-navbar">
        <div>
            <div class="d-md-none btn btn-tryb-dark burger-menu">
                <span
                    class="
                        d-block d-md-none
                        material-icons-outlined
                        float-start
                    "
                    (click)="onToggleMobileMenu()"
                >
                    menu
                </span>
            </div>
            <a routerLink="/home" class="tryb-logo">
                <img
                    src="assets/img/landing-page/tryb-logo.png"
                    srcset="
                        assets/img/landing-page/tryb-logo@2x.png 2x,
                        assets/img/landing-page/tryb-logo@3x.png 3x
                    "
                    alt="tryb logo"
                />
            </a>
        </div>
        <div class="button-start" [class.opened]="mobileMenuOpened">
            <div class="mb-3 d-block d-md-none float-end">
                <span
                    class="material-icons-outlined"
                    (click)="onToggleMobileMenu()"
                    >close</span
                >
            </div>
            <div
                class="mb-3 d-block d-md-none float-start"
                [ngClass]="{
                    'd-block': menuItemOpened,
                    'd-none': !menuItemOpened
                }"
                (click)="onCloseOpenedMenu()"
            >
                <span class="material-icons-outlined"> chevron_left </span>
                <p class="float-end">
                    <strong>{{
                        'navigation.menu.components.mobileBackButton'
                            | translate
                    }}</strong>
                </p>
            </div>
            <app-menu-button
                #menuButton
                *ngFor="let button of menuButtons"
                [menuButton]="button"
                (click)="onToggleMenuItems(menuButton)"
                (menuItemOpenStatusEvent)="onChangeMenuItemStatus($event)"
            ></app-menu-button>
        </div>
        <div
            id="nav-action-loggedin"
            class="nav-actions"
            *ngIf="isLoggedIn; else isLogOut"
        >
            <a
                *ngIf="isUserAdmin"
                id="admin"
                class="btn btn-tryb-dark nav-item"
                routerLink="/admin"
            >
                <mat-icon fontSet="material-icons">construction</mat-icon>
            </a>

            <a
                id="profile"
                class="btn btn-tryb-dark nav-item"
                routerLink="/profile"
            >
                <mat-icon
                    fontSet="material-icons"
                    *ngIf="!profilePicture"
                    id="menu-empty-profile-image"
                    >account_circle
                </mat-icon>
                <img
                    [src]="profilePicture"
                    alt="profile picture"
                    id="menu-profile-image"
                    class="image-fluid rounded-circle"
                    *ngIf="profilePicture"
                />
            </a>
            <a
                id="logout"
                class="btn btn-tryb-dark nav-item btn-mobile-logout"
                (click)="onLogout()"
            >
                <mat-icon fontSet="material-icons-outlined">logout</mat-icon>
            </a>
            <app-language-nav-menu></app-language-nav-menu>
        </div>
        <ng-template #isLogOut>
            <div id="access" class="nav-actions">
                <!-- <button
                    class="btn btn-tryb-pink btn-register"
                    (click)="onSignup()"
                >
                    <span class="d-block d-sm-none material-icons-outlined">
                        edit_note
                    </span>
                    <span class="d-none d-sm-block">Start free trial</span>
                </button> -->
                <!--Temporary commented due to LEA-2324-->
                <a
                    id="login"
                    class="
                        btn btn-tryb-dark
                        nav-item
                        btn-mobile-login
                        d-flex
                        justify-content-center
                    "
                    (click)="onLogin()"
                >
                    <mat-icon fontSet="material-icons-outlined"
                        >login
                    </mat-icon>
                </a>
                <app-language-nav-menu></app-language-nav-menu>
            </div>
        </ng-template>
    </div>
</div>
