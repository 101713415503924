import { Injectable } from '@angular/core';
import * as THREE from 'three';
import URDFLoader, { URDFJoint, URDFLink, URDFRobot } from 'urdf-loader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { BehaviorSubject, Subscription, filter, map, tap } from 'rxjs';
import { ThreejsOptions } from './threejs.options';
import { SceneStateService } from './scene-state.service';
import { LoadedSceneElement } from '../models/loaded-scene-element';
import { nonNil } from 'src/app/shared/utility';
import {
    convertOrientation,
    convertPosition,
} from '../models/state-of-scene-element';
import { NumberTranslateService } from '../../shared/services/number-translate.service';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { Object3D, Quaternion, Vector3 } from 'three';
import {
    InverseKinematicOwn,
    getRevoluteAndPrismaticJoints,
} from './inverse-Kinematic-own.service';
import { Scene } from '../models/scene';

@Injectable()
export class SceneVisualService {
    ikRuns = new BehaviorSubject<boolean>(false);
    selectedJoint = new BehaviorSubject<URDFJoint>(null);

    camera: THREE.PerspectiveCamera;
    container: HTMLElement;
    orbitControls: OrbitControls;
    transformControls: TransformControls;
    mainLight: THREE.DirectionalLight;
    ambientLight: THREE.AmbientLight;
    threeScene: THREE.Scene;
    physicallyCorrectLights = true;
    sceneBackground = 0xaaaaaa;
    renderer: THREE.WebGLRenderer;
    robot: URDFRobot;
    robotPosition: Vector3 = new Vector3(0, 0, 0);
    clock = new THREE.Clock();
    fpsDelta = 0;
    fpsInterval = 1 / 30; // means 30 Fps
    mixers = new Array<THREE.AnimationMixer>();

    raycaster = new THREE.Raycaster();

    selected$ = new BehaviorSubject<LoadedSceneElement>(null);
    inverseKinematic: InverseKinematicOwn;
    editingIsDisabled = false;
    isArrangementFormValid: BehaviorSubject<boolean> = new BehaviorSubject(
        true
    );
    currentCameraPosition: THREE.Vector3;
    isGridVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    gridVisibilitySub: Subscription;
    gridHelper: THREE.GridHelper;

    constructor(
        private sceneStateService: SceneStateService,
        private numberService: NumberTranslateService
    ) {}

    public set trainingId(trainingId: string) {
        if (trainingId) {
            this.sceneStateService.trainingId = trainingId;
        }
    }

    /** Aspectratio of width to height */
    public get aspect(): number {
        return this.container.clientWidth / this.container.clientHeight;
    }

    private start() {
        this.renderer.setAnimationLoop(() => {
            this.fpsDelta += this.clock.getDelta();
            if (this.fpsDelta > this.fpsInterval) {
                this.update(this.fpsDelta);
                this.render(this.fpsDelta);
                this.fpsDelta = 0;
            }
        });
    }

    public initialize(container: HTMLElement) {
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: true,
        });

        this.container = container;
        this.threeScene = new THREE.Scene();

        this.threeScene.background = new THREE.Color(
            ThreejsOptions.sceneBackground
        );

        this.createCamera();
        this.createLight();
        this.createAxesHelper();
        this.createRenderer();
        this.createControls();

        this.registerListeners();

        this.sceneStateService.additionalSceneElements$
            .pipe(
                tap((lses: LoadedSceneElement[]) => {
                    lses.forEach((lse: LoadedSceneElement) => {
                        this.loadSceneElement(lse);
                    });
                    this.createFloorTexture();
                })
            )
            .subscribe();

        this.sceneStateService.observeIncomingSceneElements$
            .pipe(filter(nonNil))
            .subscribe(this.loadSceneElement.bind(this));

        this.sceneStateService.observeScene$
            .pipe(
                filter(nonNil),
                tap((scene: Scene) => {
                    this.sceneStateService.observeLoadedSceneElements$.subscribe(
                        (loadedSceneElements: LoadedSceneElement[]) => {
                            const selected = loadedSceneElements.find(
                                (element: LoadedSceneElement) =>
                                    element.sceneElement.id ===
                                    scene.selectedSceneElementId
                            );
                            this.selectObject3D(selected, false);
                        }
                    );
                }),
                tap((_) => {
                    this.clearScene();
                    if (this.gridHelper) {
                        this.isGridVisible$.next(
                            this.isGridVisible$.getValue()
                        );
                    }
                })
            )
            .subscribe();

        this.sceneStateService.observeToBeRemovedSceneElements$
            .pipe(
                tap((lses) => {
                    lses?.forEach((lse: LoadedSceneElement) => {
                        this.removeObject3D(lse.object3D$?.value);
                    });
                })
            )
            .subscribe();

        this.sceneStateService.getDiversitySelection$
            .pipe(
                map(
                    (selection) =>
                        selection.base ?? selection.exampleMotion?.trajectory[0]
                ),
                filter(nonNil),
                map(
                    () =>
                        // assumes that the snapshot's states
                        // have already been updated in the loadedSceneElements
                        this.sceneStateService.baseSceneElements
                ),
                tap((loadedSceneElements: LoadedSceneElement[]) => {
                    loadedSceneElements.forEach((item: LoadedSceneElement) => {
                        if (!item.object3D$.value) return;
                        const position = convertPosition(item.state.position);
                        const orientation = convertOrientation(
                            item.state.orientation
                        );

                        this.setURDFModelData(
                            item.object3D$.value as URDFRobot,
                            item.sceneElement.browserAndPhysicsLoadable.name,
                            position,
                            orientation,
                            item.sceneElement.scale
                        );
                    });
                })
            )
            .subscribe();

        this.gridVisibilitySub = this.isGridVisible$.subscribe((isVisible) => {
            if (isVisible) {
                this.createGridHelper();
            } else {
                this.removeGridHelper();
            }
        });

        this.start();
        const selectedSceneElementId =
            this.sceneStateService.selectedSceneElementId;
        const allSceneElements = this.sceneStateService.baseSceneElements;
        allSceneElements.forEach((element: LoadedSceneElement) => {
            if (element.sceneElement.id === selectedSceneElementId) {
                this.selectObject3D(element, false);
            }
        });
    }

    disableEditing() {
        this.transformControls.detach();
        this.editingIsDisabled = true;
    }

    enableEditing() {
        this.editingIsDisabled = false;
    }

    // GEOMETRY

    public removeObject3D(object3d: THREE.Object3D) {
        if (this.selected$.value?.object3D$.value === object3d) {
            this.selected$.next(null);
            this.transformControls.detach();
        }
        this.threeScene.remove(object3d);
    }

    public removeRobot() {
        if (this.robot) {
            const sceneRobot = this.threeScene.getObjectById(this.robot.id);
            this.transformControls.detach();
            this.threeScene.remove(sceneRobot);
            this.robot = undefined;
            this.robotPosition = sceneRobot.position;
        }
    }

    private setupShadowsForObjects(object) {
        object.receiveShadow = true;
        object.castShadow = true;
        this.defineShadow(object);
        object.traverse(this.defineShadow);
    }

    private defineShadow(child) {
        if (child instanceof THREE.Mesh) {
            child.castShadow = true;
            child.receiveShadow = true;
            if (!child.material.map) {
                child.material.shininess = 50;
                if (!child.material.specular) {
                    child.material.specular = new THREE.Color();
                }
                child.material.specular.set(
                    ThreejsOptions.specularColor.r,
                    ThreejsOptions.specularColor.g,
                    ThreejsOptions.specularColor.b
                );
            }
        }
    }

    public loadURDF(loadedSceneElement: LoadedSceneElement): Promise<URDFLink> {
        const loadable =
            loadedSceneElement.sceneElement.browserAndPhysicsLoadable;
        const position = convertPosition(loadedSceneElement.state.position);
        const orientation = convertOrientation(
            loadedSceneElement.state.orientation
        );
        const manager = new THREE.LoadingManager();
        const urdfLoader = new URDFLoader(manager);
        urdfLoader.loadMeshCb = (path, mgr, done) => {
            const regexPackage = new RegExp('^http');
            let actualPath = path;
            if (!regexPackage.test(path))
                actualPath = urdfLoader.workingPath + path;
            if (/\.stl$/i.test(path)) {
                new STLLoader(mgr).load(actualPath, (geom) => {
                    const mesh = new THREE.Mesh(
                        geom,
                        new THREE.MeshPhongMaterial()
                    );
                    this.setupShadowsForObjects(mesh);
                    done(mesh);
                });
            } else if (/\.dae$/i.test(path)) {
                new ColladaLoader(mgr).load(actualPath, (collada) => {
                    const dae = collada.scene;
                    this.setupShadowsForObjects(dae);
                    done(dae);
                });
            } else if (/\.obj$/i.test(path)) {
                const loader = new OBJLoader(mgr);
                loader.load(actualPath, (objectPreLoad) => {
                    let mtllib = objectPreLoad['materialLibraries'][0];
                    if (mtllib) {
                        let dir =
                            path.substring(0, path.lastIndexOf('/')) + '/';

                        const mtlLoader = new MTLLoader(mgr);
                        mtlLoader.load(dir + mtllib, (materials) => {
                            loader.setMaterials(materials);
                            loader.load(actualPath, (object) => {
                                this.setupShadowsForObjects(object);
                                done(object);
                            });
                        });
                    } else {
                        loader.load(actualPath, (object) => {
                            this.setupShadowsForObjects(object);
                            done(object);
                        });
                    }
                });
            } else {
                console.warn(
                    `URDFLoader: Could not load model at ${actualPath}.\nNo loader available`
                );
            }
        };
        return new Promise((resolve, reject) => {
            urdfLoader.load(
                loadable.externalAddress.toString(),
                (object3D) => {
                    this.setURDFModelData(
                        object3D,
                        loadable.name,
                        position,
                        orientation,
                        loadedSceneElement.sceneElement.scale,
                        loadedSceneElement.state.jointStates
                    );
                    resolve(object3D);
                },
                undefined,
                () => reject('An error while loading the Robot!')
            );
        });
    }

    private setURDFModelData(
        model: URDFRobot,
        robotName: string,
        position: Vector3,
        orientation: Quaternion,
        scale: number,
        jointMap?: Map<string, number>
    ) {
        model.name = robotName;
        this.setupShadowsForObjects(model);
        this.threeScene.add(model);
        this.robot = model;
        const scaleDecimal = scale / 100; // working with percentage
        model.scale.set(scaleDecimal, scaleDecimal, scaleDecimal);
        this.threeScene.add(this.transformControls);
        model.position.copy(position ?? new Vector3(0, 0, 0));
        model.quaternion.copy(orientation ?? new Quaternion(0, 0, 0, 0));

        this.setJoints(model, jointMap);

        // this breaks scene element selection
        //this.selectObject3D(
        //    this.sceneStateService.findElementByThreeId(model.id),
        //    true
        //);
    }

    private setJoints(model: URDFRobot, jointStateArr: Map<string, number>) {
        if (!!jointStateArr) {
            for (let i in jointStateArr) {
                model.setJointValue(i, jointStateArr[i]);
            }
        }
    }

    public jointSelected(joint: URDFJoint) {
        if (!this.inverseKinematic) {
            this.ikInit(joint);
        } else {
            this.ikDestroy();
            this.ikInit(joint);
        }
        this.selectedJoint.next(joint);
    }

    private ikInit(joint: URDFJoint) {
        this.inverseKinematic = new InverseKinematicOwn(
            joint,
            this.robot,
            this.threeScene
        );
        this.selectObject3D(null, false, this.inverseKinematic.targetSphere);
    }

    private ikDestroy() {
        this.inverseKinematic = null;
    }

    public selectObject3D(
        newSelection: LoadedSceneElement,
        persist: boolean = true,
        targetLink?: Object3D
    ) {
        if (persist) {
            this.persistElementSelection(newSelection);
        }
        this.selected$.next(newSelection);
        this.transformControls.detach();
        if (targetLink) {
            if (!this.editingIsDisabled) {
                this.transformControls.attach(targetLink);
            }
        } else {
            if (newSelection) {
                //NOSONAR disable "var" message, which is required here
                var sub3dSelection = newSelection.object3D$
                    .pipe(filter(nonNil))
                    .subscribe((object: THREE.Object3D) => {
                        if (!this.editingIsDisabled) {
                            this.transformControls.attach(object);
                        }
                        // FIXME: Bad design with two Tree for Joint and Objects
                        this.selectedJoint.next(null);
                        sub3dSelection?.unsubscribe();
                    });
            }
        }
    }

    private persistElementSelection(selection: LoadedSceneElement) {
        if (selection) {
            const selectedId = selection.sceneElement.id;
            this.sceneStateService.setSelectedSceneElement(selectedId);
        }
    }

    private loadSceneElement(loadedSceneElement: LoadedSceneElement) {
        if (loadedSceneElement.hasBeenSetBefore) {
            return;
        }

        const loadable =
            loadedSceneElement.sceneElement.browserAndPhysicsLoadable;
        if (loadable.externalAddress) {
            this.loadSceneElementUsingEA(loadedSceneElement);
        } else {
            return;
        }
    }

    private loadSceneElementUsingEA(loadedSceneElement: LoadedSceneElement) {
        loadedSceneElement.promiseObject = this.loadURDF(loadedSceneElement);
    }

    public clearScene() {
        while (this.threeScene.children.length > 0) {
            this.removeObject3D(this.threeScene.children[0]);
        }
        this.createFloorTexture();
        this.createAxesHelper();
        this.createCamera();
        this.createControls();
        this.createLight();
    }

    /** Free all kind of resources and remove any object which consumes resources.
     * Must be called to avoid memory leaks!
     */
    public destroyScene() {
        while (this.threeScene.children.length > 0) {
            this.removeObject3D(this.threeScene.children[0]);
        }
        this.orbitControls.dispose();
        this.transformControls.dispose();
        this.renderer.dispose();
        this.gridVisibilitySub.unsubscribe();
        window.removeEventListener('resize', this.onWindowResize.bind(this));
        this.clock.stop();
    }

    // CONTROLS

    public createControls() {
        this.orbitControls = new OrbitControls(this.camera, this.container);
        this.transformControls = new TransformControls(
            this.camera,
            this.container
        );
        this.transformControls.addEventListener('dragging-changed', (event) => {
            this.orbitControls.enabled = !event.value;

            this.ikRuns.next(!this.selected$.value && !!event.value);
            if (!event.value) {
                this.transformationEnd();
            }
        });

        this.orbitControls.addEventListener('end', () => {
            this.currentCameraPosition = this.camera.position;
        });
    }

    private transformationEnd(): void {
        if (
            !!this.inverseKinematic &&
            !!this.inverseKinematic.targetSphere &&
            this.inverseKinematic.targetSphere === this.transformControls.object
        ) {
            this.inverseKinematic.realign();
        }
        this.saveCurrentState();
    }

    public saveCurrentState() {
        let loadedSceneElement = this.sceneStateService.findElementByThreeId(
            this.transformControls.object.id
        );
        if (!loadedSceneElement && !!this.selectedJoint) {
            this.sceneStateService.robot$
                .pipe(filter(nonNil))
                .subscribe((robot: LoadedSceneElement) => {
                    loadedSceneElement = robot;
                });
        }
        if (loadedSceneElement) {
            const object = loadedSceneElement.object3D$.value;
            loadedSceneElement.state.position = {
                x: this.numberService.limitNumberToDecimalPlaces(
                    object.position.x,
                    4
                ),
                y: this.numberService.limitNumberToDecimalPlaces(
                    object.position.y,
                    4
                ),
                z: this.numberService.limitNumberToDecimalPlaces(
                    object.position.z,
                    4
                ),
            };

            let jointsArr = getRevoluteAndPrismaticJoints(<URDFRobot>object);
            if (jointsArr.length > 0) {
                loadedSceneElement.state.jointStates = new Map();
                for (let j in jointsArr) {
                    let joint: URDFJoint = jointsArr[j];
                    loadedSceneElement.state.jointStates[joint.name] =
                        joint.jointValue[0].valueOf();
                }
            }

            // TODO: improve BE performance, signal only state change
            this.sceneStateService.updateStateOfLoadedSceneElement(
                loadedSceneElement.state,
                true
            );
        }
    }

    // HELPERS

    private createAxesHelper() {
        const axes = new THREE.AxesHelper(500);
        axes.name = 'AxesHelper';
        this.threeScene.add(axes);
    }

    private createGridHelper() {
        this.gridHelper = new THREE.GridHelper(50, 500);
        this.gridHelper.name = 'Grid';
        this.gridHelper.rotateX(Math.PI / 2);

        this.threeScene.add(this.gridHelper);
    }

    private removeGridHelper() {
        if (this.gridHelper) {
            this.gridHelper.dispose();
            this.threeScene.remove(this.gridHelper);
        }
    }

    // CAMERA

    private createCamera() {
        this.camera = new THREE.PerspectiveCamera(
            ThreejsOptions.fov,
            this.aspect,
            ThreejsOptions.near,
            ThreejsOptions.far
        );

        THREE.Object3D.DEFAULT_UP = new Vector3(0, 0, 1);
        this.camera.up.set(0, 0, 1);

        if (this.currentCameraPosition) {
            this.camera.position.set(
                this.currentCameraPosition.x,
                this.currentCameraPosition.y,
                this.currentCameraPosition.z
            );
        } else {
            this.camera.position.set(1, -4, 1);
        }
    }

    // LIGHTING

    private createLight() {
        this.ambientLight = new THREE.AmbientLight(
            ThreejsOptions.ambientLightOptions.color,
            ThreejsOptions.ambientLightOptions.intensity
        );
        this.ambientLight.name = 'AmbientLight';

        this.mainLight = new THREE.DirectionalLight(
            ThreejsOptions.directionalLightOptions.color,
            ThreejsOptions.directionalLightOptions.intensity
        );
        this.mainLight.name = 'MainLight';
        this.mainLight.castShadow = true;
        this.mainLight.shadow.mapSize.width = 4096;
        this.mainLight.shadow.mapSize.height = 4096;
        this.mainLight.shadow.autoUpdate = true;
        this.mainLight.position.set(10, -15, 20);
        this.threeScene.add(this.ambientLight, this.mainLight);
    }

    // FLOOR TEXTURE
    private createFloorTexture() {
        const textureLoader = new THREE.TextureLoader();

        const floorMat = new THREE.MeshStandardMaterial({
            roughness: 0.3,
            color: 0xffffff,
            metalness: 0.2,
            bumpScale: 1.0,
        });

        textureLoader.load('assets/textures/Boden.jpg', function (mapData) {
            mapData.wrapS = THREE.RepeatWrapping;
            mapData.wrapT = THREE.RepeatWrapping;
            mapData.anisotropy = 4;
            mapData.repeat.set(100, 150);
            mapData.colorSpace = THREE.SRGBColorSpace;
            floorMat.map = mapData;
            floorMat.needsUpdate = true;
        });
        textureLoader.load(
            'assets/textures/Boden_bump3.png',
            function (mapData) {
                mapData.wrapS = THREE.RepeatWrapping;
                mapData.wrapT = THREE.RepeatWrapping;
                mapData.anisotropy = 4;
                mapData.repeat.set(100, 150);
                floorMat.bumpMap = mapData;
                floorMat.needsUpdate = true;
            }
        );
        textureLoader.load(
            'assets/textures/Boden_roughness.jpg',
            function (mapData) {
                mapData.wrapS = THREE.RepeatWrapping;
                mapData.wrapT = THREE.RepeatWrapping;
                mapData.anisotropy = 4;
                mapData.repeat.set(100, 150);
                floorMat.roughnessMap = mapData;
                floorMat.needsUpdate = true;
            }
        );

        const floorGeometry = new THREE.PlaneGeometry(80, 80);
        const floorMesh = new THREE.Mesh(floorGeometry, floorMat);
        floorMesh.receiveShadow = true;
        this.threeScene.add(floorMesh);
    }

    // EVENTS

    public onWindowResize(width?: number, height?: number) {
        this.camera.updateProjectionMatrix();
        this.renderer &&
            this.renderer.setSize(
                width ? width : this.container.clientWidth,
                height ? height : this.container.clientHeight
            );
    }

    private onSelectionHandler(event: MouseEvent) {
        // OrbitalControls are disabled when Transforming
        if (!this.orbitControls.enabled || !this.isArrangementFormValid.value) {
            return;
        }
        if (event.button === 0) {
            if (!this.ikRuns.value) {
                this.configRaycaster(event);
                const intersects = this.raycaster.intersectObjects(
                    this.getSelectableObjects(),
                    true
                );
                if (intersects.length > 0) {
                    let topObj = this.getObjectOnScene(intersects[0].object);
                    if (topObj.uuid !== this.transformControls.uuid) {
                        const elem =
                            this.sceneStateService.findElementByThreeId(
                                topObj.id
                            );

                        this.selectObject3D(elem);
                    }
                } else {
                    this.selectObject3D(null);
                }
            }
        } else {
            //deselect
            this.selectObject3D(null);
        }
    }

    private getSelectableObjects(): Array<THREE.Object3D> {
        return this.threeScene.children.filter(function (object) {
            if (object.type === 'URDFLink' || object.type === 'Mesh') {
                return object;
            }
        });
    }

    /**
     * Travers from object, up before Scene is found
     * @param object Starting Object
     * @returns top most object
     */
    private getObjectOnScene(object: THREE.Object3D): THREE.Object3D {
        let resultObj = object;
        while (
            resultObj.parent !== undefined &&
            resultObj.parent.type !== 'Scene'
        ) {
            resultObj = resultObj.parent;
        }
        return resultObj;
    }

    /** Define from where to look for intersections.
     * Based on THREE.js examples look based on mouse click and camera position.
     */
    private configRaycaster(event: MouseEvent): void {
        let mouse = new THREE.Vector2();
        const domElem = this.renderer.domElement;
        mouse.x =
            ((event.clientX - domElem.offsetLeft) / domElem.clientWidth) * 2 -
            1;
        mouse.y =
            -((event.clientY - domElem.offsetTop) / domElem.clientHeight) * 2 +
            1;
        this.raycaster.setFromCamera(mouse, this.camera);
    }

    // RENDERER
    private createRenderer() {
        this.renderer.setSize(
            this.container.clientWidth,
            this.container.clientHeight
        );
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.outputColorSpace = THREE.SRGBColorSpace;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
        this.container.appendChild(this.renderer.domElement);
    }

    private registerListeners(): void {
        window.addEventListener('resize', this.onWindowResize.bind(this));

        this.container.addEventListener('pointerup', (evt) => {
            this.onSelectionHandler(evt);
        });
    }

    private update(delta: number) {
        this.mixers.forEach((x) => x.update(delta));
    }

    private render(delta: number) {
        if (this.ikRuns.value) {
            if (!!this.inverseKinematic) {
                this.inverseKinematic.step();
            }
        }
        this.renderer.render(this.threeScene, this.camera);
    }
}
