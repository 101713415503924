<div *ngIf="isUserAdmin">
    <div class="row vh-100 w-100">
        <div class="col-2 text-center navigation" id="sticky-sidebar">
            <app-admin-navigation
                (selectNavigationItemEvent)="handleNavigationItemEvent($event)"
            >
            </app-admin-navigation>
        </div>
        <div class="col" id="main">
            <app-admin-user *ngIf="isUserSelected()"></app-admin-user>
            <app-admin-trainings *ngIf="isTrainingsSelected()">
            </app-admin-trainings>
            <app-admin-performance *ngIf="isPerformanceSelected()">
            </app-admin-performance>
            <app-admin-token *ngIf="isTokenSelected()"></app-admin-token>
        </div>
    </div>
</div>
