import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { ImgWithSelection } from 'src/app/img-db/model/img-with-selection';
import { CategoryWithSelection } from '../model/category-with-selection';
import { ImgAnnotation } from '../model/img-annotation';
import { AnnotationStateService } from '../services/annotation-state.service';
import { ImgCategory } from '../model/img-category';
import { ColorService } from '../../shared/services/color.service';
import { Subscription } from 'rxjs';
import { ImagesStorageService } from '../services/images-storage.service';

@Component({
    selector: 'app-classificator-tree',
    templateUrl: './classificator-tree.component.html',
    styleUrls: ['./classificator-tree.component.scss'],
})
export class ClassificatorTreeComponent implements OnInit, OnDestroy {
    selectedImage: ImgWithSelection;
    @Input() categories: ImgCategory[] = [];
    @Output() categorySelected: EventEmitter<CategoryWithSelection> =
        new EventEmitter();
    @Output() categoryDeleted: EventEmitter<number> = new EventEmitter();

    annotations: ImgAnnotation[];
    classificatorTree = new Map<string, ClassificatorTreeNode>();
    subscriptions: Subscription[] = [];

    constructor(
        private annotationStateService: AnnotationStateService,
        private colorService: ColorService,
        private storage: ImagesStorageService
    ) {}

    ngOnInit() {
        //TODO: use forkJoin of annotationStateService and ImagesStorage to avoid fetching old annotations before images updated and shown
        // or also combineLatest can be used

        this.subscriptions.push(
            this.storage.selectedImage$.subscribe((img: ImgWithSelection) => {
                this.selectedImage = img;
            })
        );

        this.subscriptions.push(
            this.annotationStateService.$annotations.subscribe(
                (annotations: ImgAnnotation[]) => {
                    this.annotations = annotations;
                    this.castAnnotationToClassificatorTree();
                }
            )
        );
    }

    toggleClassificatorDropdown(categoryName: string) {
        this.classificatorTree.get(categoryName).open =
            !this.classificatorTree.get(categoryName)?.open;
    }

    selectClassificator(category: ClassificatorTreeItem) {
        this.annotationStateService.selectAnnotation(category.annotationId);
    }

    deleteAnnotation(classificatorTreeItem: ClassificatorTreeItem) {
        if (classificatorTreeItem.annotationId === undefined) {
            return;
        }
        this.annotationStateService.removeAnnotation(
            classificatorTreeItem.annotationId
        );
    }

    castAnnotationToClassificatorTree() {
        for (const key of this.classificatorTree.keys()) {
            this.classificatorTree.get(key).items = [];
        }

        this.annotations.forEach((annotation: ImgAnnotation) => {
            this.addNewItemToClassificatorTree(annotation);
        });

        //remove categories with 0 items
        for (const key of this.classificatorTree.keys()) {
            if (this.classificatorTree.get(key).items.length === 0) {
                this.classificatorTree.delete(key);
            }
        }
    }

    addNewItemToClassificatorTree(annotation: ImgAnnotation) {
        const category = this.categories.find(
            (cat) => cat.id === annotation.categoryId
        );

        if (!this.classificatorTree.get(category.name)) {
            this.classificatorTree.set(
                category.name,
                new ClassificatorTreeNode(false, [])
            );
        }

        const node: ClassificatorTreeNode = this.classificatorTree.get(
            category.name
        );

        node.items.push({
            annotationId: annotation.id,
            active: annotation.active,
        });
        node.open = annotation.active || node.open;
    }

    getColor(categoryName: string): string {
        return this.colorService.getColor(categoryName);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}

class ClassificatorTreeNode {
    constructor(public open: boolean, public items: ClassificatorTreeItem[]) {}
}

class ClassificatorTreeItem {
    annotationId: string;
    active: boolean;
}
