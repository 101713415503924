import { Component, Input, OnInit } from '@angular/core';
import { CustomRandomizationService } from '../services/custom-randomization.service';
import { TrainingInfoWithCode } from 'src/app/shared/models/training-info-with-code';
import { ToastMessageService } from 'src/app/shared/services/toast-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-custom-randomization',
    templateUrl: './custom-randomization.component.html',
    styleUrls: ['./custom-randomization.component.scss'],
})
export class CustomRandomizationComponent implements OnInit {
    @Input() trainingId: string;
    customCode: string;

    constructor(
        private customRandomizationService: CustomRandomizationService,
        private toastMessageService: ToastMessageService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.customRandomizationService
            .getCustomRandomizationFunction(this.trainingId)
            .subscribe((resultData: TrainingInfoWithCode) => {
                this.customCode =
                    'import numpy as np\n' +
                    'from mujoco import MjModel, MjData\n' +
                    '\n' +
                    '\n' +
                    'def randomize_state(' +
                    'model: MjModel, data: MjData, random_generator: np.random.Generator' +
                    ') -> None:\n' +
                    '    # data.qpos[0:2] += default_random(random_generator)\n' +
                    '    pass\n' +
                    '\n' +
                    '\n' +
                    'def default_random(' +
                    'random_generator: np.random.Generator,' +
                    ' low: float = -0.07,' +
                    ' high: float = 0.07,' +
                    ' size=2,' +
                    ') -> np.ndarray:\n' +
                    '    return random_generator.uniform(low=low, high=high, size=size)';
                if (resultData.code) {
                    this.customCode = resultData.code;
                }
            });
    }

    handleKeydown(event: any) {
        if (event.key === 'Tab') {
            event.preventDefault();
            const start = event.target.selectionStart;
            const end = event.target.selectionEnd;
            event.target.value =
                event.target.value.substring(0, start) +
                '\t' +
                event.target.value.substring(end);
            event.target.selectionStart = event.target.selectionEnd = start + 1;
        }
    }

    onSaveCode() {
        this.customRandomizationService
            .patchCustomRandomizationFunction(this.trainingId, this.customCode)
            .subscribe(() => {
                this.toastMessageService.showSuccessMsg(
                    this.translateService.instant(
                        'neuralNetwork.codeEditor.customRandomization.codeSavedSuccessfulyMsg'
                    )
                );
            });
    }
}
