import { map, take } from 'rxjs';
import { inject } from '@angular/core';
import { CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { LoginRedirectionService } from './login-redirection.service';
import { AuthenticationService } from './authentication.service';

export const loginGuard: CanActivateFn = (_, state: RouterStateSnapshot) => {
    const authenticationService = inject(AuthenticationService, { host: true });
    const loginRedirectionService = inject(LoginRedirectionService);

    return authenticationService.initializationFinished$.pipe(
        take(1),
        map(() => {
            return handleIsLoggedInCheck();
        })
    );

    function handleIsLoggedInCheck() {
        if (authenticationService.isLoggedIn()) {
            return true;
        } else {
            loginRedirectionService.login(state.url);
            return false;
        }
    }
};
