import { Component, Input } from '@angular/core';
import { BaseAlgorithmClass } from '../base-algorithm-class';

@Component({
    selector: 'app-ppo-parameters',
    templateUrl: './ppo-parameters.component.html',
    styleUrls: ['../../shared/algorithm-parameters.scss'],
})
export class PpoParametersComponent extends BaseAlgorithmClass {
    @Input() advancedSettingEnabled: boolean = false;

    constructor() {
        super();
    }
}
