import {
    AfterViewChecked,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Scene } from '../../models/scene';
import { SceneVisualService } from '../../services/scene-visual.service';
import { SceneStateService } from '../../services/scene-state.service';
import { filter, Observable } from 'rxjs';
import { nonNil } from 'src/app/shared/utility';
import { TrainingService } from 'src/app/shared/services/training.service';
import { TrainingDTO } from 'src/app/shared/models/training-dto';
import { TrainingStatus } from 'src/app/shared/models/training-status';

@Component({
    selector: 'app-edit-scene',
    templateUrl: './edit-scene.component.html',
    styleUrls: ['./edit-scene.component.scss'],
    providers: [SceneVisualService, SceneStateService],
})
export class EditSceneComponent implements OnInit, OnDestroy, AfterViewChecked {
    @ViewChildren('dropTarget')
    dropTargetElements: QueryList<ElementRef>;
    @ViewChildren('dragSource')
    dragSourceElements: QueryList<ElementRef>;
    waitFor: Observable<Scene>;
    editingIsDisabled: boolean = false;
    trainingName: string;

    @ViewChild('container')
    containerElement: ElementRef;

    private resizeObserver: ResizeObserver;

    constructor(
        private sceneVisualService: SceneVisualService,
        private route: ActivatedRoute,
        private sceneStateService: SceneStateService,
        private trainingService: TrainingService
    ) {}

    ngOnInit(): void {
        const trainingId = this.route.snapshot.paramMap.get('trainingId');

        this.sceneVisualService.trainingId = trainingId;

        this.waitFor = this.sceneStateService.observeScene$.pipe(
            filter(nonNil)
        );
        this.waitFor.subscribe();

        this.trainingService
            .get(trainingId)
            .subscribe((training: TrainingDTO) => {
                this.editingIsDisabled = training.status !== TrainingStatus.NEW;
                this.trainingName = training.name;
            });
    }

    ngOnDestroy(): void {
        this.sceneVisualService.destroyScene();
        this.resizeObserver.disconnect();
    }

    @ViewChild('container')
    set container(container: ElementRef) {
        if (container !== undefined) {
            this.sceneVisualService.initialize(container.nativeElement);

            if (this.editingIsDisabled)
                this.sceneVisualService.disableEditing();
        }
    }

    ngAfterViewChecked(): void {
        if (!this.resizeObserver && this.containerElement) {
            this.resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    const contentRect = entry.contentRect;
                    this.sceneVisualService.onWindowResize(
                        contentRect.width,
                        contentRect.height
                    );
                }
            });
            this.resizeObserver.observe(this.containerElement.nativeElement);
        }
    }
}
