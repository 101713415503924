import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import { Router } from '@angular/router';
import { IdmOption } from '../profile/models/idm-option';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class LoginRedirectionService {
    idmOption: IdmOption;
    readonly redirectUrl: string = 'redirectUrl';

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
        this.authenticationService.idmOption$
            .pipe(take(1))
            .subscribe((idmOption: IdmOption) => {
                this.idmOption = idmOption;
            });
    }

    login(url: string): void {
        localStorage.setItem(this.redirectUrl, url);
        this.redirect(this.idmOption.loginUri);
    }

    signup(): void {
        localStorage.setItem(this.redirectUrl, this.router.url);
        this.redirect(this.idmOption.registrationUri);
    }

    redirect(url: string): void {
        window.location.assign(url);
    }

    getRedirectUrl(): string {
        return localStorage.getItem(this.redirectUrl);
    }

    removeRedirectUrl(): void {
        localStorage.removeItem(this.redirectUrl);
    }
}
