import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './navigation/page-not-found/page-not-found.component';
import { AppRoutingModule } from './app-routing.module';
import { NavbarComponent } from './navigation/navbar/navbar.component';
import { LandingPageComponent } from './navigation/landing-page/landing-page.component';
import { Configuration } from './authentication/config/configuration';
import { CommonModule } from '@angular/common';
import { ProfilePageComponent } from './authentication/profile/components/profile-page/profile-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditSceneComponent } from './scenes/components/edit-scene/edit-scene.component';
import { AdminComponent } from './admin/components/admin/admin.component';
import {
    BrowserAnimationsModule,
    NoopAnimationsModule,
} from '@angular/platform-browser/animations';
import { AddZipObjectComponent } from './robots/components/add-zip-object/add-zip-object.component';
import { MaterialModule } from './material.module';
import { GlobalConfigComponent } from './admin/components/global-config/global-config.component';
import { UserSettingsComponent } from './admin/components/user-settings/user-settings.component';
import { UrdfComponent } from './urdf-loader/components/urdf/urdf.component';
import { UrdfListerComponent } from './robots/components/urdf-lister/urdf-lister.component';
import { UploadTypeDialogComponent } from './robots/components/upload-type-dialog/upload-type-dialog.component';
import { AddGitObjectComponent } from './robots/components/add-git-object/add-git-object.component';
import { UserComponent } from './user/user.component';
import { MenuComponent } from './navigation/menu/components/menu/menu.component';
import { MenuItemComponent } from './navigation/menu/components/menu-item/menu-item.component';
import { MenuButtonComponent } from './navigation/menu/components/menu-button/menu-button.component';
import { SliderItemComponent } from './navigation/slider-item/slider-item.component';
import { UserReviewComponent } from './navigation/user-review/user-review.component';
import { CommunityProjectComponent } from './project/component/community-project/community-project.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ForgetPasswordComponent } from './authentication/reset-password/forget-password/forget-password.component';
import { ProjectSummaryComponent } from './project/component/project-summary/project-summary.component';
import { ImageUpdateComponent } from './image-update/image-update.component';
import { UserProjectsComponent } from './project/component/user-projects/user-projects.component';
import { SharedModule } from './shared/shared.module';
import { MessageComponent } from './message/components/message.component';
import { ImageCropperComponent } from './image-update/components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ProjectWorkspaceOverviewComponent } from './project-workspace/components/project-workspace-overview/project-workspace-overview.component';
import { AdminNavigationComponent } from './admin/components/admin-navigation/admin-navigation.component';
import { AdminTrainingsComponent } from './admin/components/admin-trainings/admin-trainings.component';
import { AdminPerformanceComponent } from './admin/components/admin-performance/admin-performance.component';
import { AdminUserComponent } from './admin/components/admin-user/admin-user.component';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PlayerSelectComponent } from './player/components/player-select/player-select.component';
import { PlayerDialogComponent } from './player/components/player-dialog/player-dialog.component';
import { SelectedObject3DComponent } from './scenes/components/edit-scene/context-area/selected-object3-d/selected-object3-d.component';
import { SceneSettingsComponent } from './scenes/components/edit-scene/context-area/scene-settings/scene-settings.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { ContextAreaComponent } from './scenes/components/edit-scene/context-area/context-area.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ObjectLibraryComponent } from './scenes/components/edit-scene/context-area/object-library/object-library.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LanguageNavMenuComponent } from './navigation/menu/components/language-nav-menu/language-nav-menu.component';
import { CardModule } from './card/card.module';
import { WorkspaceModule } from './workspace/workspace.module';
import { WizardSlideComponent } from './project/component/wizard-slide/wizard-slide.component';
import { ProjectWizardComponent } from './project/component/project-wizard/project-wizard.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProjectActivityComponent } from './project-workspace/components/project-workspace-overview/components/project-activity/project-activity.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AlgorithmModule } from './algorithm/algorithm.module';
import { UserActivitiesComponent } from './user/user-activities/user-activities.component';
import { VerifyComponent } from './authentication/verify/verify.component';
import { InputSteppersComponent } from './scenes/components/edit-scene/input-steppers/input-steppers.component';
import { SceneDiversityComponent } from './scenes/components/scene-diversity/scene-diversity.component';
import { ShopwareCmsModule } from './shopware-cms/shopware-cms.module';
import { ImgDbModule } from './img-db/img-db.module';
import { EvaluationModule } from './evaluation/evaluation.module';
import { SkillArchitectureEditorModule } from './skill-architecture-editor/skill-architecture-editor.module';
import { CostFunctionModule } from './cost-function/cost-function.module';
import { AdminTokenComponent } from './admin/components/admin-token/admin-token.component';
import { ProjectWorkspaceModule } from './project-workspace/project-workspace.module';
import { SplitScreenModule } from './split-screen/split-screen.module';
import { AdminTokenNameFormComponent } from './admin/components/admin-token-name-form/admin-token-name-form.component';
import { SidebarNavComponent } from './navigation/sidebar-nav/components/sidebar-nav/sidebar-nav.component';

@NgModule({
    imports: [
        SkillArchitectureEditorModule,
        BrowserModule,
        AppRoutingModule,
        CardModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        NgbModule,
        NoopAnimationsModule,
        MaterialModule,
        SharedModule,
        ImageCropperModule,
        MatSelectModule,
        MatOptionModule,
        MatDialogModule,
        ScrollingModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        DragDropModule,
        WorkspaceModule,
        ProjectWorkspaceModule,
        SplitScreenModule,
        AlgorithmModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        InfiniteScrollModule,
        ShopwareCmsModule,
        ImgDbModule,
        CostFunctionModule,
        EvaluationModule,
        // use forRoot() in main app module only.
    ],
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        NavbarComponent,
        LandingPageComponent,
        ProfilePageComponent,
        EditSceneComponent,
        AddZipObjectComponent,
        AdminComponent,
        GlobalConfigComponent,
        UserSettingsComponent,
        UrdfComponent,
        UrdfListerComponent,
        UploadTypeDialogComponent,
        AddGitObjectComponent,
        UserComponent,
        MenuComponent,
        SidebarNavComponent,
        MenuItemComponent,
        MenuButtonComponent,
        SliderItemComponent,
        UserReviewComponent,
        UserComponent,
        CommunityProjectComponent,
        UserReviewComponent,
        SearchbarComponent,
        ResetPasswordComponent,
        ForgetPasswordComponent,
        ForgetPasswordComponent,
        ProjectSummaryComponent,
        ImageUpdateComponent,
        UserProjectsComponent,
        MessageComponent,
        ImageCropperComponent,
        ProjectWorkspaceOverviewComponent,
        MessageComponent,
        AdminNavigationComponent,
        AdminTrainingsComponent,
        AdminPerformanceComponent,
        AdminUserComponent,
        PlayerSelectComponent,
        PlayerDialogComponent,
        SceneSettingsComponent,
        SelectedObject3DComponent,
        SceneSettingsComponent,
        ContextAreaComponent,
        ObjectLibraryComponent,
        LanguageNavMenuComponent,
        WizardSlideComponent,
        ProjectWizardComponent,
        ProjectActivityComponent,
        UserActivitiesComponent,
        VerifyComponent,
        InputSteppersComponent,
        SceneDiversityComponent,
        AdminTokenComponent,
        AdminTokenNameFormComponent,
    ],
    providers: [Configuration, NavbarComponent],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/');
}
