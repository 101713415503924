import { Component, OnInit } from '@angular/core';
import { SliderItem } from '../models/slider-item';
import { UserReview } from '../models/user-review';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
    bestsellers: SliderItem[];
    userReviews: UserReview[];

    ngOnInit(): void {
        this.bestsellers = [
            {
                image: 'assets/img/landing-page/bild-tasse-greifen.png',
                icon: '',
                title: 'Tasse greifen',
                description:
                    'Lorem ipsum dolor sit arnet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            {
                image: 'assets/img/landing-page/bild-tasse-greifen.png',
                icon: '',
                title: 'Tasse greifen',
                description:
                    'Lorem ipsum dolor sit arnet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            {
                image: 'assets/img/landing-page/bild-tasse-greifen.png',
                icon: '',
                title: 'Tasse greifen',
                description:
                    'Lorem ipsum dolor sit arnet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
            {
                image: 'assets/img/landing-page/bild-tasse-greifen.png',
                icon: '',
                title: 'Tasse greifen',
                description:
                    'Lorem ipsum dolor sit arnet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            },
        ];
        this.userReviews = [
            {
                image: 'assets/img/landing-page/daniel-boehm.png',
                review: 'Mit TRYB habe ich meine LEGO-Modelle „zum Leben erweckt“. Ich kann mit TRYB schnell und einfach zum sichtbaren Erfolg kommen und viele Kontakte in der Community knüpfen“.',
                user: {
                    firstName: 'Daniel',
                    lastName: 'Böhm',
                    residence: 'Hamburg',
                    age: 32,
                    job: 'Pharmazeutisch-Technischer Assistent',
                },
            },
        ];
    }
}
