<div id="sidebar-nav-wrapper">
    <div
        id="sidebar-nav"
        class="text-white d-flex flex-column vh-100"
        [class.expanded]="isExpanded"
    >
        <a
            routerLink="/home"
            class="
                d-flex
                align-items-center
                justify-content-center
                mb-5
                mt-5
                text-white text-decoration-none
            "
        >
            <img
                src="assets/svg/sidebar-nav/tryb-logo.png"
                alt="tryb-logo"
                class="logo"
                *ngIf="isExpanded"
            />
            <img
                src="assets/svg/sidebar-nav/tryb-mini-logo.png"
                alt="tryb-mini-logo"
                class="mini-logo"
                *ngIf="!isExpanded"
            />
        </a>
        <div class="flex-fill">
            <ul class="nav nav-pills flex-column mb-auto">
                <li
                    class="nav-item"
                    [ngClass]="{ active: activeNavItem === 0 }"
                >
                    <a routerLink="/workspace" class="nav-link">
                        <img
                            src="assets/svg/sidebar-nav/workspace-icon.svg"
                            class="float-start"
                            alt="workspace-icon"
                        />
                        <span class="nav-item-text">{{
                            'navigation.sidebarNav.workspace' | translate
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="userRoles.includes('ROLE_USER_MOTORIC_TRAINING')"
                >
                    <a class="nav-link">
                        <img
                            src="assets/svg/sidebar-nav/motoric-icon.svg"
                            class="float-start"
                            alt="motoric-icon"
                        />
                        <span class="nav-item-text">{{
                            'navigation.sidebarNav.motoricProjects' | translate
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="userRoles.includes('ROLE_USER_VISUAL_TRAINING')"
                >
                    <a class="nav-link">
                        <img
                            src="assets/svg/sidebar-nav/visual-icon.svg"
                            class="float-start"
                            alt="visual-icon"
                        />
                        <span class="nav-item-text">{{
                            'navigation.sidebarNav.visualProjects' | translate
                        }}</span>
                    </a>
                </li>
                <li
                    class="nav-item"
                    *ngIf="userRoles.includes('ROLE_USER_VISUAL_TRAINING')"
                >
                    <a class="nav-link">
                        <img
                            src="assets/svg/sidebar-nav/picdatabase-icon.svg"
                            class="float-start"
                            alt="picdatabase-icon"
                        />
                        <span class="nav-item-text">{{
                            'navigation.sidebarNav.imageDatabses' | translate
                        }}</span>
                    </a>
                </li>
                <li class="nav-item separation-line">
                    <div class="nav-link">
                        <img
                            class="dashed-line"
                            src="assets/svg/sidebar-nav/separating-line-expanded.svg"
                            alt="separating-line-expanded"
                        />
                    </div>
                </li>
                <li
                    class="nav-item"
                    [ngClass]="{ active: activeNavItem === 4 }"
                >
                    <a class="nav-link" routerLink="/communityprojects">
                        <img
                            src="assets/svg/sidebar-nav/gallery-icon.svg"
                            class="float-start"
                            alt="gallery-icon"
                        />
                        <span class="nav-item-text">{{
                            'navigation.sidebarNav.gallery' | translate
                        }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div>
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item" (click)="onExpand()">
                    <a class="nav-link">
                        <img
                            src="assets/svg/sidebar-nav/arrow-close.svg"
                            class="float-start collapse-button"
                            alt="arrow-close"
                        />
                        <span class="nav-item-text">{{
                            'navigation.sidebarNav.collapse' | translate
                        }}</span>
                    </a>
                </li>
            </ul>
        </div>
        <div
            class="user-section"
            [ngClass]="{ 'user-section-expanded': isUserSectionExpanded }"
        >
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item user-section-header">
                    <a class="nav-link" (click)="onUserSectionExpand()">
                        <img
                            id="profile-img"
                            [src]="
                                picture
                                    ? picture
                                    : 'assets/svg/sidebar-nav/profile-icon.svg'
                            "
                            alt="profile-picture"
                        />
                        <span class="nav-item-text">{{ username }}</span>
                        <img
                            src="assets/svg/sidebar-nav/arrow-up.svg"
                            class="float-start user-section-expand-arrow"
                            alt="arrow-up"
                        />
                    </a>
                </li>
                <div
                    *ngIf="isUserSectionExpanded"
                    class="user-section-options-wrapper"
                >
                    <li
                        class="nav-item"
                        [ngClass]="{
                            'user-section-active': activeNavItem === 5
                        }"
                    >
                        <a routerLink="/profile" class="nav-link">
                            <img
                                src="assets/svg/sidebar-nav/profile-icon.png"
                                alt="profile-icon"
                            />
                            <span class="nav-item-text">{{
                                'navigation.sidebarNav.profile' | translate
                            }}</span>
                        </a>
                    </li>
                    <li
                        class="nav-item"
                        *ngIf="isAdmin"
                        [ngClass]="{
                            'user-section-active': activeNavItem === 6
                        }"
                    >
                        <a routerLink="/admin" class="nav-link">
                            <img
                                src="assets/svg/sidebar-nav/admin-icon.png"
                                alt="admin-icon"
                            />
                            <span class="nav-item-text">{{
                                'navigation.sidebarNav.admin' | translate
                            }}</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="onLogOut()">
                            <img
                                src="assets/svg/sidebar-nav/log-out-icon.png"
                                alt="log-out-icon"
                            />
                            <span class="nav-item-text">{{
                                'navigation.sidebarNav.logOut' | translate
                            }}</span>
                        </a>
                    </li>
                </div>
            </ul>
        </div>
    </div>
    <div
        id="color-stripe"
        class="vh-100"
        [ngClass]="{
            'workspace-color': activeNavItem === 0,
            'gallery-color': activeNavItem === 4,
            'profile-color': activeNavItem === 5,
            'admin-color': activeNavItem === 6
        }"
    ></div>
</div>
