<div
    id="{{ menuButton.elementId }}_container"
    class="inline-button"
    [class.menu-button-open]="opened"
>
    <button
        [id]="menuButton.elementId"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu_items"
        class="menu-button noto-font-bold"
        *ngIf="menuButton.items.length > 0"
        (menuOpened)="open(true)"
        (menuClosed)="open(false)"
    >
        <span class="mat-menu-text" translate>{{ menuButton.title }}</span>

        <mat-icon
            class="d-none d-md-block arrow-icon menu-arrow-icon"
            [class.menu-open]="opened"
            >expand_more
        </mat-icon>

        <mat-icon class="d-block d-md-none float-end">chevron_right</mat-icon>
    </button>

    <button
        [id]="menuButton.elementId"
        class="menu-button noto-font-bold"
        *ngIf="menuButton.items.length === 0"
        [routerLink]="menuButton.routerLink"
        (click)="onMenuButtonClick()"
    >
        <span translate>{{ menuButton.title }}</span>
    </button>
</div>
<mat-menu #menu_items>
    <div class="col p-0" *ngFor="let array of orderedItems; let i = index">
        <div class="row">
            <ng-container *ngFor="let item of array">
                <button
                    class="col-12 col-md-6 col-lg menu-item-button"
                    mat-menu-item
                >
                    <app-menu-item [item]="item"></app-menu-item>
                </button>
            </ng-container>
            <ng-container
                *ngIf="extraColumnCount > 0 && i === orderedItems.length - 1"
            >
                <div class="col" *ngFor="let empty of emptyContainers"></div>
            </ng-container>
        </div>
    </div>
    <div class="nav-border"></div>
</mat-menu>
